import React from 'react'
// import { s } from '../style'
// import { useLangContext } from '../context/lang.context'

const Label = ({ text, color, noL, noR, children, sExtraCss }) => {
  // const { lang } = useLangContext()
  return (
    <div css={[sLabel, { color }, sExtraCss]}>
      {!noL && (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 44.024 47'>
          <path
            id='Path_366'
            data-name='Path 366'
            d='M2055.711-868.048c-.56-1.747,8.51-13.771,25-39,3.611-4.632,8.875-7.708,14-8h5v47Z'
            transform='translate(-2055.687 915.048)'
            fill='currentColor'
          />
        </svg>
      )}
      {text && <span css={[{ backgroundColor: color }]}>{text}</span>}
      {children}

      {!noR && (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 44.024 47'>
          <path
            id='pathR'
            d='M2099.687-868.048c.56-1.747-8.51-13.771-25-39-3.611-4.631-8.875-7.707-14-8h-5v47Z'
            transform='translate(-2055.687 915.048)'
            fill='currentColor'
          />
        </svg>
      )}
    </div>
  )
}

const sLabel = {
  height: '3rem',
  display: 'flex',
  // [s.sm_down]: {
  //   width: '100%',
  // },

  span: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    padding: '1rem 2rem',
    color: 'white',
    fontWeight: 700,
    letterSpacing: '0em',
    whiteSpace: 'nowrap',
  },

  svg: {
    height: '100%',
    '&:nth-of-type(1)': {
      transform: 'translateX(2px)',
    },
    '&:nth-of-type(2)': {
      transform: 'translateX(-2px)',
    },
  },
}

export default Label
