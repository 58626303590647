import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { useLangContext } from '../../context/lang.context'
import LinkGridItem from '../../components/LinkGridItem'
import { s } from '../../style'

const CsrAreas = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      sport: file(
        absolutePath: { regex: "/images/csr/sport.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [WEBP]
            aspectRatio: 1.77
          )
        }
      }
      social: file(
        absolutePath: { regex: "/images/csr/social.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [WEBP]
            aspectRatio: 1.77
          )
        }
      }
      edu: file(absolutePath: { regex: "/images/csr/edu.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [WEBP]
            aspectRatio: 1.77
          )
        }
      }
      charity: file(
        absolutePath: { regex: "/images/csr/charity.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [WEBP]
            aspectRatio: 1.77
          )
        }
      }
      others: file(
        absolutePath: { regex: "/images/csr/others.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [WEBP]
            aspectRatio: 1.77
          )
        }
      }
    }
  `)

  const links = [
    {
      title: {
        pl: 'Edukacja i rozwój',
        en: 'Blog',
        ua: '',
      },
      slug: '',
      text: `Wspieramy edukację i inspirujące inicjatywy, jak na przykład lot rakiety Perun z Centralnego Poligonu Sił Powietrznych w Ustce.`,
      imageBack: query.edu.childImageSharp,
    },
    {
      title: {
        pl: 'Działalność charytatywna',
        en: 'Supporting',
        ua: '',
      },
      slug: '',
      text: `Aktywnie wspieramy fundacje i organizacje charytatywne takie jak: To Help Africa, Dom Hospicyjny dla Dzieci Bursztynowa Przystań, Gdyńska Hematologia, Szpital Polanki w Gdańsku.`,
      imageBack: query.charity.childImageSharp,
    },
    {
      title: {
        pl: 'Społeczność',
        en: 'Career',
        ua: 'КАРЄРА',
      },
      slug: '',
      text: `Angażujemy się w życie lokalnej społeczności, organizując wydarzenia oraz wspierając lokalne inicjatywy i projekty społeczne.`,
      imageBack: query.social.childImageSharp,
    },
    {
      title: {
        pl: 'Sport',
        en: 'Company',
        ua: 'БІЗНЕС',
      },
      slug: '',
      text: `Promujemy zdrowy styl życia poprzez wspieranie sportowców, tworzenie firmowych drużyn sportowych czy angażowanie się w sportowe inicjatywy.`,
      imageBack: query.sport.childImageSharp,
    },
    {
      title: {
        pl: 'Pozostałe',
        en: 'Supporting',
        ua: '',
      },
      slug: '',
      text: `Stawiamy na zrównoważony rozwój i innowacje w naszych operacjach logistycznych, minimalizując nasz wpływ na środowisko.`,
      imageBack: query.others.childImageSharp,
    },
  ]

  return (
    <div css={sGrid}>
      {links.map((link, id, text) => (
        <LinkGridItem data={link} key={id} id={id} text={link.text}/>
      ))}
    </div>
  )
}

const sGrid = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '4rem',
  marginTop: '6rem',
  [s.sm_down]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    marginTop: '6rem',
    gap: '2rem',
  },
}

export default CsrAreas
