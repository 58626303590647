import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { useLangContext } from '../../context/lang.context'
import { seo, intro, misja, wsparcie, news, people } from '../../content/csr'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import Intro from '../../components/Intro'
import Main from '../../components/Main'
import CsrAreas from '../../sections/csr/CsrAreas'
import CsrEvents from '../../sections/csr/CsrEvents'


const Firma = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/csr.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [WEBP]
          )
        }
      }
      people: file(absolutePath: { regex: "/images/csr/people.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [WEBP, AVIF]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Obszary zaangażowania',
      en: 'Get to know Sea & Air',
      ua: 'ПІЗНАЙТЕ SEA & AIR',
    },
    link: '#obszary-zaangazowania'
  }

  const buttonSecond = {
    text: {
      pl: 'Inicjatywy',
      en: 'Find an office',
      ua: 'ЗНАЙТИ ВІДДІЛЕННЯ',
    },
    link: '#inicjatywy'
  }


  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
        noIndex
      />
      <Intro
        data={{ ...intro, button, buttonSecond }}
        image={query.image.childImageSharp}
        position='bottom'
        noEffects
      />
      <Main
        id={`nasza-misja`}
        h={1}
        s={1}
        title={misja.title[lang]}
        desc={misja.desc[lang]}
        subtitle={misja.subtitle[lang]}
      />
      <Main
        id={`obszary-zaangazowania`}
        h={2}
        s={1}
        title={wsparcie.title[lang]}
        desc={wsparcie.desc[lang]}
        subtitle={wsparcie.subtitle[lang]}
        smallerOnMobile
      >
        <CsrAreas/> 
      </Main>
      <Main
        id={`inicjatywy`}
        h={2}
        s={1}
        title={news.title[lang]}
        // desc={news.desc[lang]}
        subtitle={news.subtitle[lang]}
      >
        <CsrEvents />
      </Main>
      <Main
        id={`sila-ludzi`}
        h={2}
        s={1}
        title={people.title[lang]}
        desc={people.desc[lang]}
        subtitle={people.subtitle[lang]}
      >
        <div css={imageWrapper}>
          <div css={Effect2}></div>
          <div css={Effect1}></div>
          <GatsbyImage
            image={getImage(query.people.childImageSharp)}
            css={sImage}
          />
        </div>
      </Main>
    </Layout>
  )
}

const imageWrapper = {
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '60px',
  minHeight: '426px'
}

const Effect1 = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: '0',
  right: '0px',
  top: '0',
  bottom: '0',
  background: 'linear-gradient(90deg, rgba(48, 72, 114, 0.301961) 0%, rgba(48, 72, 114, 0.6) 100%)',
  mixBlendMode: 'overlay',
  opacity: 0.55,
  zIndex: 2,
}

const Effect2 = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: '0',
  right: '0px',
  top: '0',
  background: 'linear-gradient(90deg, #1A72AD 0%, #1A72AD 37.23%, #001D30 100%)',
  mixBlendMode: 'soft-light',
  zIndex: 3,
}

const Effect3 = {
  zIndex: 4, 
}

const sImage = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover'
}


export default Firma