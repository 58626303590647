import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import BlogTile from '../../components/BlogTile'
import Button from '../../components/Button'
import { useLangContext } from '../../context/lang.context'
import { s } from '../../style'

const CsrEvents = ({ skip }) => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      csrs: allContentfulCsr(sort: { fields: date, order: DESC }) {
        nodes {
          blog_slug
          title
          description
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              quality: 100
              placeholder: DOMINANT_COLOR
              formats: [JPG]
              aspectRatio: 1.77
            )
          }
        }
      }
    }
  `)

  const content = {
    button: {
      text: {
        pl: 'Więcej inicjatyw',
        en: 'More articles',
      },
      link: '/csr/',
    },
  }

  const csrs = query.csrs.nodes
    .map(({ title, description, image, blog_slug }) => ({
      title,
      desc: description,
      slug: blog_slug,
      image,
    }))

  return (
    <>
      <div css={sBlogs}>
        {csrs
          .filter((blog, id) => (skip ? id < skip : true))
          .map((blog, id) => (
            <BlogTile key={id} data={blog} noExcerpt prefix={`/csr/`} />
          ))}
      </div>
      {skip && (
        <Button link={content.button.link}>{content.button.text[lang]}</Button>
      )}
    </>
  )
}

export const sBlogs = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '4rem',
  margin: '4rem 0',
  [s.sm_down]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: '2rem',
    margin: '4rem 0 2rem',
  },
}

export default CsrEvents
