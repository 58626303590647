export const seo = {
  url: 'csr',
  title: {
    pl: 'Społeczna Odpowiedzialność Biznesu',
    en: 'Top rank forwarding company',
    ua: 'Транспортно-експедиційна компанія вищого рангу',
  },
  desc: {
    pl: `Działamy, aby tworzyć pozytywne zmiany i budować lepszą przyszłość dla społeczności i środowiska. Dołącz do naszej misji biznesowej z sercem!`,
    en: `Meet a company with Polish capital, European spirit and Global reach. Together, we build an advantage on the market.`,
    ua: `Разом ми створюємо перевагу на ринку. Познайомтеся з компанією з польським капіталом, європейським духом і глобальним охопленням.`,
  },
  keywords: [
    'omida',
    'sea and air',
    'sea & air',
    'firma spedycyjna',
    'firma logistyczna',
    'firma transportowa',
  ],
}

export const intro = {
  title: {
    pl: 'Społeczna Odpowiedzialność Biznesu',
    en: 'Omida Sea & Air',
    ua: 'Omida Sea & Air',
  },
  desc: {
    pl: `Działamy, aby tworzyć pozytywne zmiany i budować lepszą przyszłość dla społeczności i środowiska. Dołącz do naszej misji biznesowej z sercem!`,
    en: `Meet a company with Polish capital, European spirit and Global reach. Together, we build an advantage on the market.`,
    ua: `Разом ми створюємо перевагу на ринку. Познайомтеся з компанією з польським капіталом, європейським духом і глобальним охопленням.`,
  },
}

export const misja = {
  title: {
    pl: 'Społeczna Odpowiedzialność Biznesu w Omida Sea And Air',
    en: 'Discover Sea & Air - forwarding company with over 10 years of experience in TSL',
    ua: 'Відкрийте Sea & Air - експедитор з більш ніж 10-річним досвідом роботи в TSL',
  },
  subtitle: {
    pl: 'Misja',
    en: 'Discover Sea & Air - forwarding company with over 10 years of experience in TSL',
    ua: 'Відкрийте Sea & Air - експедитор з більш ніж 10-річним досвідом роботи в TSL',
  },
  desc: {
    pl: 'Społeczna odpowiedzialność biznesu stanowi integralną część naszej strategii. Poza realizacją celów biznesowych, aktywnie uczestniczymy w inicjatywach społecznych. Nasze działania nie tylko wzmacniają markę, ale również przyczyniają się do pozytywnych zmian w społecznościach, w których działamy. Wierzymy, że biznes może być siłą napędową do wprowadzania korzystnych zmian w wielu obszarach.',
    en: 'We provide transport that connects the whole world. Every year, we finalize thousands of transports and shipments, providing full logistic and forwarding services. We improve our skills, broaden our knowledge and gain new experience. We provide sea, air and rail transport, employing specialists in their fields.',
    ua: 'Ми надаємо транспорт, який зєднує весь світ. Щороку ми завершуємо тисячі перевезень і відправлень, надаючи повні логістичні та експедиторські послуги. Ми вдосконалюємо свої навички, розширюємо знання та отримуємо новий досвід. Ми здійснюємо морські, авіаційні та залізничні перевезення, залучаючи фахівців у своїй галузі.',
  },
}

export const wsparcie = {
  title: {
    pl: 'Obszary zaangażowania',
    en: 'Discover Sea & Air - forwarding company with over 10 years of experience in TSL',
    ua: 'Відкрийте Sea & Air - експедитор з більш ніж 10-річним досвідом роботи в TSL',
  },
  subtitle: {
    pl: 'CSR - Wspieramy',
    en: 'Discover Sea & Air - forwarding company with over 10 years of experience in TSL',
    ua: 'Відкрийте Sea & Air - експедитор з більш ніж 10-річним досвідом роботи в TSL',
  },
  desc: {
    pl: 'W Omida Sea And Air działamy w różnych obszarach społecznej odpowiedzialności biznesu, aby tworzyć pozytywne zmiany mające wpływ zarówno na społeczeństwo, jak i środowisko. Wspieramy rozwój społeczności poprzez inicjatywy edukacyjne, inwestujemy w zdrowie i aktywność fizyczną poprzez wsparcie sportu, angażujemy się w działalność charytatywną, budując wrażliwość społeczną. Naszym celem jest osiąganie zarówno sukcesów biznesowych, jak i wywieranie pozytywnego wpływu na nasze otoczenie.',
    en: 'We provide transport that connects the whole world. Every year, we finalize thousands of transports and shipments, providing full logistic and forwarding services. We improve our skills, broaden our knowledge and gain new experience. We provide sea, air and rail transport, employing specialists in their fields.',
    ua: 'Ми надаємо транспорт, який зєднує весь світ. Щороку ми завершуємо тисячі перевезень і відправлень, надаючи повні логістичні та експедиторські послуги. Ми вдосконалюємо свої навички, розширюємо знання та отримуємо новий досвід. Ми здійснюємо морські, авіаційні та залізничні перевезення, залучаючи фахівців у своїй галузі.',
  },
}

export const news = {
  title: {
    pl: 'Aktualne inicjatywy, projekty, wydarzenia CSR',
    en: 'Discover Sea & Air - forwarding company with over 10 years of experience in TSL',
    ua: 'Відкрийте Sea & Air - експедитор з більш ніж 10-річним досвідом роботи в TSL',
  },
  subtitle: {
    pl: 'CSR - Aktualności',
    en: 'Discover Sea & Air - forwarding company with over 10 years of experience in TSL',
    ua: 'Відкрийте Sea & Air - експедитор з більш ніж 10-річним досвідом роботи в TSL',
  },
  desc: {
    pl: 'Donec rutrum congue leo eget malesuada. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui.',
    en: 'We provide transport that connects the whole world. Every year, we finalize thousands of transports and shipments, providing full logistic and forwarding services. We improve our skills, broaden our knowledge and gain new experience. We provide sea, air and rail transport, employing specialists in their fields.',
    ua: 'Ми надаємо транспорт, який зєднує весь світ. Щороку ми завершуємо тисячі перевезень і відправлень, надаючи повні логістичні та експедиторські послуги. Ми вдосконалюємо свої навички, розширюємо знання та отримуємо новий досвід. Ми здійснюємо морські, авіаційні та залізничні перевезення, залучаючи фахівців у своїй галузі.',
  },
}

export const people = {
  title: {
    pl: 'Siła ludzi na pełnym morzu możliwości',
    en: 'Discover Sea & Air - forwarding company with over 10 years of experience in TSL',
    ua: 'Відкрийте Sea & Air - експедитор з більш ніж 10-річним досвідом роботи в TSL',
  },
  subtitle: {
    pl: 'Nasz Zespół – Nasza Siła',
    en: 'Discover Sea & Air - forwarding company with over 10 years of experience in TSL',
    ua: 'Відкрийте Sea & Air - експедитор з більш ніж 10-річним досвідом роботи в TSL',
  },
  desc: {
    pl: `Fundamentem naszej działalności jest nasz zespół - załoga Omida Sea And Air. Jako zespół łączymy profesjonalizm, odpowiedzialność oraz wzajemne poszanowanie wspólnych wartości. Stawiamy na rozwój osobisty każdego członka naszej załogi, pogłębiając wiedzę i zdobywając nowe doświadczenia. Jesteśmy otwarci na świat i nowe pomysły, co umacnia ducha pracy zespołowej i pozwala płynąć nam przez najtrudniejsze biznesowe fale. Wspólnie budujemy lepsze jutro dla nas i przyszłych pokoleń.`,
    en: 'We provide transport that connects the whole world. Every year, we finalize thousands of transports and shipments, providing full logistic and forwarding services. We improve our skills, broaden our knowledge and gain new experience. We provide sea, air and rail transport, employing specialists in their fields.',
    ua: 'Ми надаємо транспорт, який зєднує весь світ. Щороку ми завершуємо тисячі перевезень і відправлень, надаючи повні логістичні та експедиторські послуги. Ми вдосконалюємо свої навички, розширюємо знання та отримуємо новий досвід. Ми здійснюємо морські, авіаційні та залізничні перевезення, залучаючи фахівців у своїй галузі.',
  },
}
