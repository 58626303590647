import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { useLangContext } from '../context/lang.context'
import { alpha, colors, s } from '../style'
import alt from '../content/alt'
import Label from '../components/Label'
import { navigate } from '@reach/router'

const LinkGridItem = ({ data, id, text }) => {
  const { lang } = useLangContext()
  const { title, slug, imageBack, imageFront } = data
  return (
    <div
      css={sOuter}
      onClick={slug ? () => navigate(slug) : ''}
      onKeyPress={slug ? () => navigate(slug) : ''}
      role='button'
      tabIndex={0}
    >
      <GatsbyImage
        imgClassName={`AboutSectio-back`}
        image={getImage(imageBack)}
        alt={title[lang] + alt}
        css={sImageBack}
      />
      <GatsbyImage
        imgClassName={
          id === 1
            ? `AboutSectio-front AboutSectio-front-reversed`
            : `AboutSectio-front`
        }
        image={getImage(imageFront)}
        alt={title[lang] + alt}
        css={sImageFront}
      />
      <div css={sEffect} />
      {/* <Link css={sLink}>
        <Label
          noR
          color={colors.brandDark}
          text={title[lang]}
          sExtraCss={sLabel}
        />
      </Link> */}
      {text && (
        <div className={`BackgroundText`} css={sHoverText} dangerouslySetInnerHTML={{ __html: text }} />
      )}
      {slug ? (
        <Link to={slug} css={sLink} className={text ? `LinkGridLabel` : ``}>
          <Label
            noR
            color={colors.brandDark}
            text={title[lang]}
            sExtraCss={sLabel}
          />
        </Link>
      ) : (
        <div className={text ? `LinkGridLabel` : ``} css={sLink}>
          <Label
            noR
            color={colors.brandDark}
            text={title[lang]}
            sExtraCss={sLabel}
          />
        </div>
      )}
    </div>
  )
}

const sOuter = {
  // cursor: 'pointer',
  position: 'relative',
  top: 0,
  left: 0,
  borderRadius: '2rem',
  overflow: 'hidden',

  '.AboutSectio-front, .AboutSectio-back': {
    transition: 'transform 0.3s ease-out',
  },
  '.AboutSectio-front-reversed': {
    transform: 'scale(1.1) translateY(-5%)',
  },
  '&:hover': {
    [s.hover]: {
      '.AboutSectio-front': {
        transform: 'scale(0.9) translateY(5%)',
      },
      '.AboutSectio-back': {
        transform: 'scale(1.1)',
      },
      '.AboutSectio-front-reversed': {
        transform: 'scale(1.0) translateY(0%)',
      },
      a: {
        filter: 'brightness(1.25)',
      },
      '.BackgroundText': {
        opacity: 1
      },
      '.LinkGridLabel svg': {
        visibility: 'hidden'
      },
      '.LinkGridLabel span': {
        background: 'transparent'
      }
    },
  },
  [s.xs]: {
    borderRadius: '1.5rem',
  }
}

const sImageFront = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  zIndex: 3,
  borderRadius: '2rem',
  [s.xs]: {
    borderRadius: '1.5rem',
  }
}

const sImageBack = {
  borderRadius: '2rem',
  img: {
    borderRadius: '2rem',
  },
  [s.xs]: {
    borderRadius: '1.5rem',
    img: {
      borderRadius: '1.5rem',
    }
  }
}

const sLink = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  zIndex: 3,
  borderBottomRightRadius: '2rem',
  overflow: 'hidden',
  transition: 'filter 0.2s ease-out',
  [s.xs]: {
    borderBottomRightRadius: '1.5rem',
  }
}

const sEffect = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 3,
  // prettier-ignore
  background: `linear-gradient(to bottom, 
    ${colors.brandDarker.concat(alpha[0])},
    ${colors.brandBack.concat(alpha[0])},
    ${colors.brandBack.concat(alpha[60])}
  )`,
  mixBlendMode: 'multiply',
  borderRadius: '2rem',
}

const sLabel = {
  [s.xs]: {
    height: '2rem',
  },
  span: {
    fontSize: '.75rem',
    // paddingRight: '1.25rem',
    // paddingLeft: '0.25rem',
    padding: '.5rem 1.25rem .5rem 0.25rem'
  },

  [s.sm_only]: {
    height: '4rem',
    span: {
      fontSize: '1.25rem',
      lineHeight: '30px',
      padding: '1rem 2rem 1rem 0.25rem',
    },
  },
  [s.md]: {
    span: {
      fontSize: '.75rem',
      padding: '1rem 1.25rem 1rem 0.25rem'
    },
  },
  [s.slg]: {
    height: '4rem',
    span: {
      fontSize: '1.25rem',
      lineHeight: '30px',
      paddingRight: '',
    },
  }
}

const sHoverText = {
  opacity: 0,
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '100%',
  padding: '1rem',
  background: 'linear-gradient(270deg, rgba(26, 114, 173, 0.9) 0%, rgba(48, 72, 114, 0.9) 100%)',
  transition: 'opacity 0.3s ease-out',
  fontSize: '16px',
  lineHeight: '1.15',
  color: '#fff',
  [s.sm]: {
    padding: '3rem 2rem 2rem',
    fontSize: '22px',
    lineHeight: '1.5',
  },
  [s.md]: {
    padding: '2rem 1.25rem 1.25rem',
    fontSize: '20px',
    lineHeight: '1.36',
  },
  [s.slg]: {
    padding: '3rem 2rem 2rem',
    fontSize: '24px',
    lineHeight: '1.5',
  }
}

export default LinkGridItem
